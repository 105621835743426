import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import format from 'date-fns/format';
import { enUS, nl } from 'date-fns/locale';
import { formatDistanceStrict } from 'date-fns';
import { FaNewspaper } from 'react-icons/fa';
import useMemoSelector from 'hooks/useMemoSelector';

import ImageSliderBadge from 'Components/Plugins/Sliders/ImageSliderBadge';

import getTimePassed from 'utils/getTimePassed';
import { MEDIA, GLOBAL_CONSTANTS } from 'utils/constants';
import sortByTranslation from 'utils/sortByTranslation';

import { deviceParams, baseSelector } from 'store/reselect';

import useStyles from './styles';

const companyIconName = 'business';

const SingleAdView = ({
  data: {
    city,
    type,
    title,
    labels,
    gender,
    tagline,
    start_date,
    description,
    photos = [],
    category_id,
    date_bumped,
    profile_name,
    date_modified,
    title_slug: slug,
    promotion: adPromotion,
  } = {},
}) => {
  const t = useTranslations();
  const { lang } = useSelector(baseSelector());
  const { deviceType } = useMemoSelector(deviceParams());

  const styles = useStyles({ isMobile: deviceType === 'mobile' });

  const isEvent = type === 'event';

  const productLeftDate = getTimePassed(
    new Date().valueOf() - new Date(start_date).valueOf(),
    true,
    t,
  );

  const currentCategory = GLOBAL_CONSTANTS.categories.find(
    el => el.id === category_id,
  );

  const generatedImagePaths = photos?.map(
    photo =>
      `${MEDIA.storagePath}/${photo.path}/${photo.hash}_thumb_248.${photo.ext}?v=${photo.v}`,
  ) || ['/images/icons/upload.png'];

  const promotionIcon =
    adPromotion === 'silver' ? (
      <img src="/images/icons/silver_icon.svg" alt="gold" />
    ) : adPromotion === 'gold' ? (
      <img src="/images/icons/gold_icon.svg" alt="gold" />
    ) : (
      ''
    );

  const eventStartingDate = format(new Date(start_date), 'dd MMM');

  const currentLocale = lang === 'nl' ? nl : enUS;

  return (
    <div>
      <Link href={`/${slug}`}>
        <a>
          <span className={`${styles['badge__block']}`}>
            <div
              className="image-wrapper"
              style={{
                width: deviceType === 'mobile' ? '160px' : '190px',
              }}
            >
              {adPromotion && (
                <div
                  className="promotion-icon"
                  style={{
                    width: isEvent ? '75px' : '20px',
                    backgroundColor:
                      adPromotion === 'silver' ? '#c7c7c7' : '#d5b356',
                  }}
                >
                  {promotionIcon}
                  {isEvent && (
                    <span style={{ color: '#fff', fontWeight: 'bold' }}>
                      {eventStartingDate}
                    </span>
                  )}
                </div>
              )}
              <ImageSliderBadge src={generatedImagePaths} />
            </div>
            <div className={styles['details--block']}>
              <div>
                <h4 className="title-content">
                  <b>{title}&nbsp;</b>
                  {tagline && (
                    <span
                      style={{ marginTop: 4 }}
                      className={`sticker__tile sticker__tile--${
                        adPromotion || 'silver'
                      }`}
                    >
                      {tagline}
                    </span>
                  )}
                </h4>
                <p className="gender-type__block">
                  <img
                    src={`/images/icons/${gender || companyIconName}.svg`}
                    alt="gender-icon"
                  />
                  {profile_name}
                </p>
                {currentCategory?.value && (
                  <p className="gender-type__block">
                    <FaNewspaper />
                    {t(currentCategory.value)}
                  </p>
                )}

                {isEvent && (
                  <div className={styles['details--block-section__bt']}>
                    <p>
                      <span
                        className="icon-Tag"
                        style={{ marginRight: '10px' }}
                      ></span>
                      {t(labels[0])}
                    </p>
                    <p>
                      <span
                        className="icon-Time"
                        style={{ marginRight: '10px' }}
                      ></span>
                      {format(new Date(start_date), 'hh:mm')}
                    </p>
                  </div>
                )}
                <div className="details--block-activation">
                  <span className="icon-Time"></span>
                  {isEvent
                    ? productLeftDate?.map((date, index) => (
                        <p key={date} style={{ margin: '0' }}>
                          {date}
                          {productLeftDate.length - 1 === index && (
                            <span> {t('event_time_left')}</span>
                          )}
                        </p>
                      ))
                    : formatDistanceStrict(
                        new Date(date_bumped ?? date_modified),
                        new Date(),
                        {
                          includeSeconds: true,
                          locale: currentLocale,
                        },
                      )}
                </div>
                <div className="details--block-location">
                  <span className="icon-Locatie"></span>
                  {city ?? 'No city'}
                </div>
                <p className="description__block">{description}</p>
              </div>
              <p className="labels__block">
                {!isEvent &&
                  labels?.length &&
                  sortByTranslation({ data: labels }, t).map(label => (
                    <span key={label}>{t(label)}</span>
                  ))}
              </p>
            </div>
          </span>
        </a>
      </Link>
    </div>
  );
};

export default SingleAdView;

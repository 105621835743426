import { useRouter } from 'next/router';
import useMemoSelector from 'hooks/useMemoSelector';

import Listing from 'Components/Listing';
import ViewOfAd from 'Components/ViewOfAd';
import ContentBlock from 'Components/ContentBlock';

import jsonSafeParse from 'utils/jsonSafeParse';
import generateAdvertisementsPayload from 'utils/generateAdvertisementsPayload';
import { DONT_SHOW_ADS } from 'utils/constants';

import { getSelectedCategory, getUrlStructure } from 'store/reselect';
import { fetchAdConfigs } from 'store/actions';

import API from 'services/api';

import replaceUrlStructureData from '../../utils/replaceUrlStructureData';

const ListingPage = props => {
  const router = useRouter();
  const urlStructure = useMemoSelector(getUrlStructure);

  const { adSettings: { adsPreference } = {} } = urlStructure;

  const needToDrawOnlyContent = adsPreference === DONT_SHOW_ADS;

  const isInAdDetailedPage = router.query.slug?.find(slug => /_\d+/.test(slug));

  const Component = isInAdDetailedPage
    ? ViewOfAd
    : needToDrawOnlyContent
    ? ContentBlock
    : Listing;

  return <Component {...props} />;
};

const getAdDetailData = async ctx => {
  const result = { isAdValid: true, adPromotions: {}, data: {} };
  try {
    const referenceId = ctx.asPath.slice(ctx.asPath.lastIndexOf('_') + 1);
    const { data: adStatistics } = await API.fetchAdStatistics(
      referenceId,
      'public',
    );
    const validAdUrl = adStatistics?.title_slug;

    if (validAdUrl && ctx.asPath !== `/${validAdUrl}`) {
      result.isAdValid = false;
      ctx.res.statusCode = 404;
    } else {
      result.adStatistics = adStatistics;
    }

    const { data: adData } = await API.getAd({
      id: referenceId,
      status: 'public',
    });
    result.data = adData;

    const { data: promotions } = await API.fetchAdPromotions(referenceId);
    result.adPromotions = promotions;

    const { data: profileData } = await API.getAdDetailsProfiles({
      ids: adData._meta?.profile_ids,
      status: 'public',
    });

    result.profileData = profileData;

    const { data: imagesDetailedData } =
      await API.getAdsPublicImagesByProfileId({
        ad_id: adData._id,
        purpose: 'ad',
      });

    const imagesIdsCollection = imagesDetailedData?.map(item => item.image_id);

    if (imagesIdsCollection?.length) {
      const { data: imgPaths } = await API.getImagesBulk(
        { ids: imagesIdsCollection },
        'public',
      );
      result.imgPaths = imgPaths;
    }

    const {
      data: {
        premiumxs: { phone_number },
      },
    } = await API.getPaymentsConfig();

    result.phoneNumberConfig = phone_number;
  } catch (e) {
    result.isAdValid = false;
    ctx.res.statusCode = 404;
  }

  return result;
};

const getListingData = async ctx => {
  let needToRedirect = false;
  const slug = ctx.query.slug.map((path, index) => {
    const needToOverwriteLabel = path === 'gratis-sexcontact' && index === 1;
    if (needToOverwriteLabel) {
      needToRedirect = true;
      return 'gratis-sexcontact-label';
    }
    return path;
  });

  const selectedCategory = getSelectedCategory(ctx.store?.getState(), slug);

  if (ctx.isServer) {
    const { category_id, selectedFilters, ...rest } = ctx.query;

    const { data } = await API.validateUrl({
      ...rest,
    });

    const urlStructure = replaceUrlStructureData(data);

    const props = {};

    if (urlStructure.statusCode) {
      ctx.res.statusCode = urlStructure.statusCode;

      if (urlStructure.statusCode === 404) {
        return props;
      }
    }

    const userLocation =
      ctx.req?.cookies?.location && jsonSafeParse(ctx?.req?.cookies?.location);

    const payload = generateAdvertisementsPayload({
      ...urlStructure,
      userLocation,
      term: ctx.query.term,
      distance: ctx.query.distance,
      category_id:
        urlStructure.category_id ??
        (ctx.query.category_id && Number(ctx.query.category_id)),
    });

    const categoryTitle = selectedCategory?.title || '';

    const searchedValueFromUrl =
      categoryTitle ||
      urlStructure.selectedCity ||
      urlStructure.selectedProvince ||
      ctx.query.term ||
      ctx.query.settlement ||
      ctx.query.province;

    props.searchedValueFromUrl = searchedValueFromUrl;

    if (needToRedirect) {
      ctx.res.writeHead(302, { Location: `/${slug.join('/')}` });
      ctx.res.end();
    }

    if (
      ctx.res &&
      urlStructure.selectedFiltersHash &&
      urlStructure.selectedFiltersHash !== selectedFilters
    ) {
      ctx.res.writeHead(302, {
        Location: `/${slug.join('/')}?selectedFilters=${
          urlStructure.selectedFiltersHash
        }`,
      });
      ctx.res.end();
    }

    if (ctx.query.selectedFilters) {
      const {
        data: { result: selectedFilters },
      } = await API.gatAdSelectedFilters(ctx.query.selectedFilters);

      props.selectedFilters = selectedFilters;
    }

    const {
      data: { labels },
    } = await API.fetchAdFilters({
      types: 'labels',
      category_id: Number(selectedCategory?.id),
    });

    props.labels = labels;

    const filters = {
      ...selectedCategory,
      ...payload,
    };

    if (urlStructure.isAvailableNow) {
      const {
        data: { result, count },
      } = await API.fetchDynamicAggregatorAds('available-now', 0, 30);
      props.categoryInfo = result;
      props.categoryCount = count;
    } else if (Object.keys(filters).length) {
      const page = Number(ctx.query.page);

      const {
        data: { result, count },
      } = await API.fetchAdListing({
        offset: page ? (page - 1) * 30 : 0,
        limit: 30,
        filters,
      });

      props.categoryInfo = result;
      props.categoryCount = count;
    }

    if (!props.categoryCount) {
      ctx.res.statusCode = 404;
    }

    return props;
  }
};

ListingPage.getInitialProps = async ({ ctx }) => {
  const { store } = ctx;

  const isInAdDetailedPage = ctx.query.slug.find(slug => /_\d+/.test(slug));

  if (!store?.getState().ads.adConfigs.hasOwnProperty('ad_types')) {
    await new Promise(resolve => {
      store.dispatch(fetchAdConfigs({ cb: () => resolve() }));
    });
  }

  return await (isInAdDetailedPage
    ? getAdDetailData(ctx)
    : getListingData(ctx));
};

export default ListingPage;

const detectDeviceByUserAgent = userAgent => {
  if (/tablet|ipad|playbook|silk/i.test(userAgent)) {
    return 'tablet';
  } else if (/mobile/i.test(userAgent)) {
    return 'mobile';
  } else {
    return 'desktop';
  }
};

export default detectDeviceByUserAgent;

import Banner from 'Components/Banner';
import SingleAdView from 'Components/Smart/Badges/SingleAdView';
import Vacancies from 'Components/Smart/Tiles/General/Vacancies';

const ListingItem = ({ updateData, ad, deviceType, index }) => {
  const { reference_id, type } = ad;

  const needToShowBanner = index === 2 || index === 10;

  const AdComponent = type === 'vacancy' ? Vacancies : SingleAdView;

  return (
    <div
      ref={node => {
        if (node) {
          updateData(reference_id, node.offsetTop);
        }
      }}
      key={reference_id}
    >
      <AdComponent data={ad} key={ad.reference_id} />
      {needToShowBanner && (
        <Banner size={deviceType === 'desktop' ? '970x90' : '300x100'} />
      )}
    </div>
  );
};

export default ListingItem;

import { memo } from 'react';
import { useTranslations } from 'next-intl';

import useStyles from './styles';

const SeekLabels = ({ data }) => {
  const t = useTranslations();
  const styles = useStyles();

  return (
    <div className="content-with-shadow mb-20 save-bg-on--mobile">
      <div className={styles['prices--block']}>
        <h3 className="title-content">{t('seek_labels_title')}</h3>
        <ul>
          {data.map(({ name, id }) => (
            <li key={id}>
              <span>{t(name)}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default memo(SeekLabels);

import { makeStyles } from '@material-ui/core/styles';
import { MediaQuery } from 'static/styles/jss/abstracts/mixins';

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles(theme => ({
  dialog: {
    '& .input-element-wrapper': {
      color: 'red',
    },
    '& .MuiDialogTitle-root, .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialog-paper': {
      minWidth: '600px',
      padding: '32px 64px',
      backgroundColor: '#061E5A',
      ...MediaQuery.down({
        minWidth: '100%',
        padding: '32px 24px',
      }).xss,
    },
    '& label': {
      fontSize: '15px !important',
    },
  },
  container: {
    paddingTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white',
    gap: '24px',
  },
  logo: {
    maxWidth: '200px',
  },
  description: {
    textAlign: 'center',
    color: 'white',
  },
  columnsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    ...MediaQuery.down({
      flexDirection: 'column',
      gap: '24px',
    }).xsss,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    ...MediaQuery.down({
      width: '140px',
      margin: '0 auto',
    }).xsss,
  },
  heading: {
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  input: {
    '& label': {
      color: 'white',
    },
  },
  button: {
    backgroundColor: '#176CDB !important',
    ...MediaQuery.down({
      marginTop: '24px !important',
    }).xss,
    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
    color: 'white',
    cursor: 'pointer',
    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },
}));
